@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

@layer components {
    .card {
        background-color: theme("colors.white");
        border-radius: theme("borderRadius.lg");
        border-color: theme("colors.gray.300");
        border-width: 1px;
    }
}

.watchItemTemplate {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.watchItemContentTemplate {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto 1fr auto;
}

.dashboardTemplateRows {
    grid-template-rows: auto 1fr;
}

.watchItemPriceHistoryGrid {
    grid-template-columns: 1fr auto auto;
}
